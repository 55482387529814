@import "src/assets/styles/customs/variables";

.spacer {
  width: 1px;
  margin: 0 100px;
  background-color: $spacer;
  opacity: 0.2;

  @media (max-width: $mobileMaxWidth) {
    margin: 0 22px;
  }
}

.mydata-request-detail {
  &__services-container {
    margin-top: 40px;
    border-radius: 20px;
    padding: 40px;
    background-color: $white;

    @media (max-width: $mobileMaxWidth) {
      margin-top: 32px;
      padding: 0 24px;
    }

    & > ul {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        position: relative;
        padding: 0 0 44px 32px;
        margin-top: 8px;

        @media (max-width: $mobileMaxWidth) {
          padding-left: 31px;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        &:before {
          content: " ";
          width: 8px;
          height: 8px;
          background-color: $point400;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 3px;
        }

        &:after {
          content: " ";
          width: 1px;
          height: calc(100% - 19px);
          left: 3.5px;
          top: 19px;
          position: absolute;
          background-color: $point200;
        }
      }
    }

    &__timestamp {
      font-family: $sharpSansFont;
      color: $timestampColor;
    }

    &__title {
      margin-top: 8px;
      display: flex;
      align-items: center;

      @media (max-width: $mobileMaxWidth) {
        margin-top: 6px;

        & .title3 {
          font-size: 18;
          line-height: 1.33;
          display: flex;
          flex: 1;
        }
      }

      &__status {
        margin-left: 8px;
        color: $point;
        background-color: $point50;
        padding: 4px 10px;
        border-radius: 12px;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 8px;
        }

        &.status-W {
          background-color: $purple50;
          color: $purple500;
        }

        &.status-T {
          background-color: $green100;
          color: $green800;
        }

        &.status-E {
          background-color: $grayEe;
          color: $gray75;
        }

        &.status-S {
          background-color: #feebef;
          color: $negative;
        }

        &.status-D {
          background-color: #feebef;
          color: $negative;
        }
      }
    }

    &__list {
      display: block;
      margin-top: 18px;
      padding: 8px 24px;
      border-radius: 12px;
      border: 1px solid $line;
      cursor: pointer;
      background-color: $white;
      position: relative;

      [data-theme="hc"] & {
        background-color: transparent;
      }

      &:after {
        @media (max-width: $mobileMaxWidth) {
          visibility: hidden;
        }

        content: " ";
        height: 16px;
        width: 16px;
        background: url(#{$iconChevronRight});
        position: absolute;
        top: calc(50% - 8px);
        right: 24px;

        [dir="rtl"] & {
          right: initial;
          left: 24px;
          transform: rotate(180deg);
        }

        [data-theme="hc"] & {
          background: url(#{$iconChevronRightHc});
        }
      }

      @media (max-width: $mobileMaxWidth) {
        margin-top: 16px;
      }

      & .col {
        display: flex;
        align-items: center;
        margin: 8px 0;

        &.more-services {
          & > img {
            margin-left: -12px;

            [dir="rtl"] & {
              margin-left: 0;
              margin-right: -12px;
            }

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      & .service-icon {
        border-radius: 50%;
      }

      & .service-name {
        font-size: 16px;
        margin-left: 12px;
        font-family: $sharpSansFont;
        color: $tab1depthTextColor !important;

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 12px;
        }
      }

      &.faded {
        background-color: $grayEe;

        [data-theme="hc"] & {
          background-color: transparent;
        }
        & .col {
          opacity: 0.25;
        }
      }
    }
  }
}
